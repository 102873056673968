<template>
  <div class="container-fluid">
    <BrandNavTab />
    <h2 class="roboto bold">List Brands</h2>
    <div class="row no-gutters">
      <div v-for="(brand, i) in brands" :key="i" class="col-md-4">
        <div class="p-1">
          <BrandShowcase
            :brand="brand"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import BrandNavTab from '@/components/brand/BrandNavTab.vue';
import BrandShowcase from "@/components/brand/BrandShowcase";

export default {
  name: "BrandList",
  components: {
    BrandNavTab,
    BrandShowcase,
  },
  data() {
    return {
      brands: [],
    }
  },
  mounted() {
    this.getOwnedBrands()
  },
  methods: {
    async getOwnedBrands() {
      try {
        const res = await this.$api.cms.getBrands()
        if (res.status === 200) {
          const d = res.data
          this.getHighlights(d.data)
        }
        else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
    async getHighlights(brands) {
      try {
        for (let i = 0; i < brands.length; i++) {
          const brand = brands[i].brand
          brand.highlights = brands[i].highlight_product_list
          this.brands.push(brand)
          // const res = await this.$api.brand.getHighlights(brands[i].id)
          // if (res.status === 200) {
          //   const d = res.data
          //   const highlights = d.data.map(el => {
          //     delete el.brand
          //     return el
          //   })
          //   brands[i]["description"] = brands[i].description_banner
          //   brands[i]["highlights"] = [...highlights, ...Array(3 - highlights.length).fill(null)]
          //   this.brands.push(brands[i])
          // } else toaster.make(res.data.message, "danger")
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
}
</script>
